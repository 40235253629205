

































































import Vue from 'vue';
import HostTelegramModuleService from '@/services/owner/HostTelegramModuleService';
import DataTable from '@/components/shared/DataTable.vue';
import Jumbotron from '@/components/shared/Jumbotron.vue';
import CreateCard from '@/components/forms/CreateCard.vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import { MetaInfo } from 'vue-meta';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ToastMessage from '@/models/ToastMessage';

class TelegramConnectionDto {
  telegramChatId!: String;
  telegramUsername!: String;
}

const validations = {
  telegramConnection: {
    telegramUsername: { required },
  },
};

export default Vue.extend({
  components: { DataTable, Jumbotron, ValidatedInput, CreateCard },
  mixins: [validationMixin],
  validations,
  data() {
    return {
      telegramConnections: {} as { items: TelegramConnectionDto[] },
      telegramConnection: new TelegramConnectionDto(),
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'id', asc: true },
      currentPage: 1,
      loading: false,
      ready: false,
      message: '',
      botName: '',
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  mounted() {
    this.setBreadCrumb();
    this.loadData();
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostTelegramModule.meta.title').toString(),
    };
  },
  computed: {
    telegramStartUrl(): string {
      return 'https://telegram.me/' + this.botName + '?start=vCH1vGWJxfSeofSAs0K5PA';
    },
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.host.HostTelegramModule.breadcrumb.last' },
      ]);
    },

    deleteTelegramChatId(telegramConnectionId: string) {
      this.loading = true;
      return HostTelegramModuleService.deleteTelegramConnection(telegramConnectionId).then(
        () => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
          );
          this.loadData();
        },
        (error) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-warning')
          );
          this.loading = false;
          this.message = error;
        }
      );
    },

    handleSubmit() {
      this.loading = true;
      return HostTelegramModuleService.createNew(this.telegramConnection.telegramUsername).then(
        () => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
          );
          this.loadData();
        },
        (error) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-warning')
          );
          this.loading = false;
          this.message = error;
        }
      );
    },

    loadData() {
      this.loading = true;
      HostTelegramModuleService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.ready = true;
        this.loading = false;
        this.telegramConnections = res.value.telegramConnections;
        this.botName = res.value.botName;
        this.pages = res.value.telegramConnections.totalPages;
      });
    },
  },
});
