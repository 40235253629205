import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/TelegramModule/';

class HostTelegramModuleService {
  deleteTelegramConnection(telegramConnectionId: string) {
    return ApiService.delete(`${API_URL}DeleteTelegramConnection?id=${telegramConnectionId}`);
  }

  createNew(telegramUsername: String) {
    return ApiService.post(`${API_URL}Create`, { telegramUsername });
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }
}

export default new HostTelegramModuleService();
